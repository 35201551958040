import DynamicOnboardingPage from "../components/OnboardingModules/DynamicOnboardingPage";
import { ConfigType } from "../context/AppContext";

interface ContentItem {
  title: string;
  description: string;
  classname: string;
  rowType: string;
  items: any[];
}

interface FlowPages {
  [key: string]: {
    pageTitle: string;
    classname: string;
    content_items: ContentItem[];
  };
}

interface FlowPostType {
  [key: string]: {
    flow_pages: string[];
    buttonStyle?: string;
    triggerEvent: "before" | "after";
    triggerDelay?: string;
    classname?: string;
    fullscreen?: boolean;
    id?: string;
  };
}

interface PagesPostType {
  [key: string]: {
    flow?: string[];
    pageTitle: string;
    classname: string;
    content_items: ContentItem[];
  };
}

function useFlows() {
  const resultBeforeType = [];
  const resultAfterType = [];

  const pagesPostType: PagesPostType = window.config.pages;
  const flowPostType: FlowPostType = window.config.flows;
  const flowPages: FlowPages = window.config.flow_pages;

  Object.keys(pagesPostType).forEach((key) => {
    const flowReferences = pagesPostType[key]?.flow || [];
    // console.log(pagesPostType[key]?.flow);
    if (flowReferences && flowPostType) {
      flowReferences.flatMap((flow_reference) => {
        const { flow_pages, buttonStyle, triggerEvent, triggerDelay, classname, fullscreen, id } =
          flowPostType[flow_reference];

        // Determine the type once, rather than multiple times
        const type = triggerEvent === "before" ? "page" : "modal";

        const mappedComponents = flow_pages.map((page) => ({
          Component: () =>
            DynamicOnboardingPage({
              json: flowPages[page],
              type: type,
              buttonStyle: buttonStyle,
            }),
        }));

        // Construct the object based on the type
        const resultObject: ConfigType = {
          name: key,
          buttonStyle: buttonStyle,
          components: mappedComponents,
        };

        // Add fields only for 'before' type (i.e. pages);
        if (type === "page") {
          if (id) resultObject.before_id = id;
        }

        // Add fields only for 'after' type (i.e., modal)
        if (type === "modal") {
          if (triggerDelay) resultObject.triggerDelay = triggerDelay;
          if (classname) resultObject.classname = classname;
          if (id) resultObject.after_id = id;
          if (typeof fullscreen !== "undefined") resultObject.fullscreen = fullscreen;
        }

        // Push to the appropriate array
        (type === "page" ? resultBeforeType : resultAfterType).push(resultObject);

        // console.log(resultObject);
        return mappedComponents;
      });
    }
  });

  return { before: resultBeforeType, after: resultAfterType };
}

export default useFlows;
